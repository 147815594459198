import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpContextToken } from '@angular/common/http';
import { LoaderService } from '../shared/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const SHOW_LOADER = new HttpContextToken(() => true);

class HttpErrorResponse {}

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Promise.resolve(null).then(() => {
      if (request.context.get(SHOW_LOADER)) {
        this.totalRequests++;
        this.loaderService.showLoader();
      }
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),

      finalize(() => {
        if (request.context.get(SHOW_LOADER)) {
          this.totalRequests--;
          if (this.totalRequests === 0) {
            this.loaderService.hideLoader();
          }
        }
      })
    );
  }
}
