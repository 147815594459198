import { Component, Input, OnInit } from '@angular/core';
import { NOTIFICATION_SUBJECT_TYPE } from '../../../../shared/enums';
import { NotificationResponse } from '../../../../shared/intarfaces';
import { FloatingMenuService } from '../../../../shared/services/floating-menu.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  imports: [CommonModule, RouterModule, LazyLoadDirective],
  standalone: true,
})
export class NotificationItemComponent implements OnInit {
  @Input()
  data: NotificationResponse = {} as NotificationResponse;

  NOTIFICATION_SUBJECT_TYPE = NOTIFICATION_SUBJECT_TYPE;

  constructor(public floatingMenuService: FloatingMenuService, public notificationService: NotificationService) {
  }

  link: { link: string; templateLink: string } = {
    link: '',
    templateLink: ''
  };

  ngOnInit() {
    this.link = this.notificationService.getLink(this.data);
  }

  closeDrawer() {
    this.floatingMenuService.closeDrawer();
    this.floatingMenuService.reset();
  }
}
