<div class="fan-area-menu">
  <span *ngFor="let item of items" class="fan-area-menu__item" [routerLink]="item.path">
    <div class="fan-area-menu__item-icon">
      <span class="icon {{ item.icon }}"></span>

      <div
        *ngIf="item.title === FUN_AREA_MENU_TITLES.NOTIFICATION && (notificationService.hasNewNotifications$ | async)"
        class="notification"></div>
    </div>

    <span>{{ item.title }}</span>
  </span>
</div>
