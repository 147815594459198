import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-bell-icon',
  templateUrl: './bell-icon.svg',
  imports: [CommonModule],
  standalone: true
})
export class BellIconComponent {

}
