import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { Response, NotificationResponse } from '../intarfaces';
import { SHOW_LOADER } from '../../interceptors/loader.interceptor';
import { NOTIFICATION_SUBJECT_TYPE } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsSubject$: BehaviorSubject<NotificationResponse[]> = new BehaviorSubject<NotificationResponse[]>(
    [] as NotificationResponse[]
  );
  public notifications$: Observable<NotificationResponse[]> = this.notificationsSubject$.asObservable();
  public hasNewNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getNotifications(): Observable<NotificationResponse[]> {
    return this.http
      .get<Response<NotificationResponse[]>>(`${environment.gaiminApi}/notification`, {
        headers: this.authService.authorizationHeader(),
        context: new HttpContext().set(SHOW_LOADER, false)
      })
      .pipe(
        map((response) => response.data?.sort((a, b) => b.id - a.id) || []),
        tap((sortedNotifications) => {
          this.notificationsSubject$.next(sortedNotifications);

          this.hasNewNotifications$.next(sortedNotifications.some((item) => item.new));
        })
      );
  }

  readNotifications() {
    return this.http.put(`${environment.gaiminApi}/notification/read`, null, {
      headers: this.authService.authorizationHeader(),
      context: new HttpContext().set(SHOW_LOADER, false)
    });
  }

  removeNotification(id: number) {
    this.notificationsSubject$.next(this.notificationsSubject$.value.filter((item) => item.id !== id));

    return this.http.put(`${environment.gaiminApi}/notification/${id}`, null, {
      headers: this.authService.authorizationHeader(),
      context: new HttpContext().set(SHOW_LOADER, false)
    }).subscribe();
  }

  getLink(item: NotificationResponse) {
    const templateLink = window.location.origin;

    switch (item.subjectType) {
      case NOTIFICATION_SUBJECT_TYPE.STREAMER:
        let channelName = item.message.split(' ')[0];

        return {
          link: '/streams/' + channelName,
          templateLink: templateLink + '/streams/' + channelName
        };
      case NOTIFICATION_SUBJECT_TYPE.ARTICLE:
        return {
          link: '/article/' + item.subjectId,
          templateLink: templateLink + '/article/' + item.subjectId
        };
      case NOTIFICATION_SUBJECT_TYPE.VIDEO:
        return {
          link: '/videos/' + item.subjectId,
          templateLink: templateLink + '/videos/' + item.subjectId
        };
      default:
        return {
          link: '',
          templateLink: ''
        };
    }
  }
}
