import { Component } from '@angular/core';
import { NotificationService } from '../../../../shared/services/notification.service';
import { EmptyComponent } from '../../empty/empty.component';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [CommonModule, EmptyComponent, NotificationItemComponent],
  standalone: true
})
export class NotificationListComponent {
  constructor(public notificationService: NotificationService) { }
}
