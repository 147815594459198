import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { CHAINS } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class WalletRequestsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  storeActiveUserWalletRequest(walletAddress: string) {
    const chain = environment.production ? CHAINS.BNB_CHAIN : CHAINS.BNB_CHAIN_TESTNET;
    this.http
      .put(
        `${environment.gaiminApi}/users/me/wallet/active`,
        { wallet: walletAddress, chain },
        {
          headers: this.authService.authorizationHeader()
        }
      )
      .subscribe();
  }

  checkDpaEventEnrollment() {
    this.authService.checkDpaEventEnrollment(false, true);
  }
}
