import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { BuyGmrxModalComponent } from '../../components/modals/buy-gmrx-modal/buy-gmrx-modal.component';
import { Button } from '../../intarfaces';
import { BUTTON_SIZE } from '../../enums';
import { FanAreaMenuComponent } from '../../../shared/components/fan-area-menu/fan-area-menu.component';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { TooltipModule } from '../../directives/tooltip-module/tooltip.module';
import { LazyLoadDirective } from '../../directives/lazy-load.directive';

export interface SideMenu {
  title: string;
  redirectLink: string;
  icon: string;
  disabled?: boolean;
  hideDesktop?: boolean;
  bottomMenu: boolean;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [CommonModule, RouterModule, ButtonComponent, TooltipModule, MatBottomSheetModule, LazyLoadDirective],
  standalone: true,
})
export class SidebarComponent implements OnInit {
  @AutoUnsubscribe()
  private isDesktopSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private routerSub: Subscription = {} as Subscription;

  isSideBarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly buyGMRXBtn: Button = {
    name: 'BUY GMRX',
    size: BUTTON_SIZE.MD
  };

  readonly sideMenu: SideMenu[] = [
    {
      title: 'Content',
      redirectLink: '/content',
      icon: 'icon-Home-2',
      bottomMenu: true
    },
    {
      title: 'Streams',
      redirectLink: '/streams',
      icon: 'icon-Streams',
      bottomMenu: true
    },
    {
      title: 'Fan Area',
      redirectLink: '/fan-area',
      icon: 'icon-Star',
      disabled: true,
      bottomMenu: true,
    },
    {
      title: 'Rewards',
      redirectLink: '/rewards',
      icon: 'icon-cup',
      bottomMenu: false
    },
    {
      title: 'Players',
      redirectLink: '/players',
      icon: 'icon-Nep-Squad',
      bottomMenu: false
    },
    {
      title: 'Store',
      redirectLink: '/store',
      icon: 'icon-Market',
      bottomMenu: true
    },
    // {
    //   title: 'Auction',
    //   redirectLink: '/auction',
    //   icon: 'icon-Wallet',
    //   disabled: true,
    //   bottomMenu: false
    // },
    {
      title: 'Charity',
      redirectLink: '/charity',
      icon: 'icon-Dashboard',
      disabled: true,
      bottomMenu: false
    }
  ];

  constructor(
    private router: Router,
    private dialogService: MatDialog,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    public userService: UserService
  ) {
    this.isDesktopSub = this.breakpointObserver.observe(['(min-width: 769px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.bottomSheet.dismiss();
      }
    });
  }

  openProfile() {
    this.closeSidebar();
    this.router.navigateByUrl('profile');
  }

  openSidebar() {
    this.isSideBarOpen$.next(true);
  }

  closeSidebar() {
    this.isSideBarOpen$.next(false);
  }

  openRoot() {
    this.router.navigateByUrl('/');
  }

  openBottomSheet() {
    this.bottomSheet.open(FanAreaMenuComponent, {
      panelClass: 'sheet-fan-area-menu',
      backdropClass: 'sheet-fan-area-menu-backdrop',
      closeOnNavigation: true
    });
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

  buyGMRX() {
    this.closeSidebar();
    this.dialogService.open(BuyGmrxModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-buy-gmrx']
    });
  }

  ngOnInit(): void {
    this.routerSub = this.router.events
    .pipe(
      filter(() => !!this.bottomSheet._openedBottomSheetRef)
    )
    .subscribe(() => {
      this.bottomSheet.dismiss();
    });
  }
}
