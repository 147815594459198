import { Injectable, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, timer } from 'rxjs';
import { NotificationService } from './notification.service';
import { FAN_AREA_MENU_TITLES } from '../enums';

interface FloatingMenuItem {
  title: FAN_AREA_MENU_TITLES;
  icon: string;
}

@Injectable({
  providedIn: 'root'
})
export class FloatingMenuService {
  private renderer: Renderer2 | null = null;

  isDrawerOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  imagePath = 'assets/icons/floating-menu/';
  items: FloatingMenuItem[] = [
    {
      title: FAN_AREA_MENU_TITLES.NOTIFICATION,
      icon: this.imagePath + 'bell.svg',
    }
  ]

  selectedItemIndex: number | null = null;
  selectedItemTitle: FAN_AREA_MENU_TITLES | null = null;

  constructor(
    private toastrService: ToastrService,
    public notificationService: NotificationService
  ) {
  }

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  openDrawer(title: FAN_AREA_MENU_TITLES) {
    this.isDrawerOpen$.next(true);
    this.selectedItemTitle = title;
    this.selectedItemIndex = this.items.findIndex(item => item.title === title);

    this.toastrService.clear();
    this.renderer?.setStyle(document.body, 'overflow', 'hidden');

    if (title === FAN_AREA_MENU_TITLES.NOTIFICATION && this.notificationService.hasNewNotifications$.value) {
      timer(5000).subscribe(() => {
        this.notificationService.readNotifications().subscribe(() => {
          this.notificationService.hasNewNotifications$.next(false);

          this.notificationService.getNotifications().subscribe();
        })
      });
    }
  }

  closeDrawer() {
    this.isDrawerOpen$.next(false);
    this.renderer?.removeStyle(document.body, 'overflow');
  }

  reset() {
    this.selectedItemIndex = null;
    this.selectedItemTitle = null;
  }
}
