<div class="floating-menu" [ngClass]="{ active: floatingMenuService.isDrawerOpen$ | async }">
  <ng-container *ngFor="let item of floatingMenuService.items">
    <span
      *ngIf="item.title === FAN_AREA_MENU_TITLES.NOTIFICATION && (isNotificationRoute$ | async) === false"
      class="floating-menu__item"
      [ngClass]="{
        notification:
          item.title === FAN_AREA_MENU_TITLES.NOTIFICATION && (notificationService.hasNewNotifications$ | async),
        active: floatingMenuService.selectedItemTitle === item.title
      }"
      (click)="floatingMenuService.openDrawer(item.title)">
      <app-bell-icon></app-bell-icon>
    </span>
  </ng-container>
</div>

<div class="drawer__wrapper" [ngClass]="{ active: floatingMenuService.isDrawerOpen$ | async }">
  <div class="drawer" [ngClass]="{ active: floatingMenuService.isDrawerOpen$ | async }" #drawer>
    <div class="drawer__header">
      <span class="drawer__title">{{ floatingMenuService.selectedItemTitle }}</span>
      <span class="icon-Close close-icon" title="Close" (click)="closeDrawer()"></span>
    </div>

    <app-notification-list></app-notification-list>
  </div>
</div>
